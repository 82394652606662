.cover {
  background: #e8e9ec;
  height: 100vh;
  // transform: matrix(1, 0, 0, -1, 0, 0);

  display: grid;
  justify-content: center;
  align-items: center;
}

.tabLanding {
  text-align: center;
  width: 360px;
  height: 572px;
  background: #f3f4f5;
  box-shadow: -10px -10px 25px 12px #ffffff, 10px 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}
.logo {
  margin-top: 30px;
  width: 40%;
}

.mobile{
  
  margin-bottom:6%;
}
.fram {
  margin-top: 4px;
  width: 35%;
}
.framdown {
  margin-top: 24%;
  width: 35%;
}
.signin_btn {
  cursor: pointer;
  width: 241px;
  height: 48px;
  background: #fc6603;
  border-radius: 45px;
  border: none;
  margin: 2%;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}
.signup_btn {
  cursor: pointer;
  width: 241px;
  height: 48px;
  background: #ffffff;
  border-radius: 45px;
  border: none;
  margin: 2%;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}
.input_phone {
  flex-direction: row;
  width: 241px;
  height: 40px;
  background: #ffffff;
  border-radius: 43px;
  opacity: 0.7;
  font-size: 15;
  padding-left: 6%;
  
  color: #1c1d1c;
  border: none;
}
.dateinput{
  flex-direction: row;
  width: 241px;
  height: 40px;
  background: #ffffff;
  border-radius: 43px;
  opacity: 0.7;
  font-size: 15;
  padding-left: 6%;
  padding-right: 2%;
  color: #1c1d1c;
  border: none;
}

.labelInput {
  margin-top: 8%;
  margin-left: -15%;
  font-weight: 430;
  font-size: 14px;
}
.label {
  margin-top: 8%;
text-align: start;
  font-weight: 430;
  font-size: 14px;
}
.otp-input-container {
  justify-content: space-between;
  margin: 8%;
}
.mpin-input-container {
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 5%;
  
}
.mpin-input {
  width: 35px !important;
  margin-right: auto;
  height: 45px !important;
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
  background: #fff;
  // border: 1px solid rgba(51, 101, 138, 0.3);
  border: none;
  border-radius: 12px;
  margin-bottom: 13%;
}

.otp-input {
  width: 45px !important;
  margin-right: auto;
  height: 60px !important;
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
  background: #fff;
  // border: 1px solid rgba(51, 101, 138, 0.3);
  border: none;
  border-radius: 8px;
  margin-bottom: 10%;
}

.mpin-input:focus {
  outline: #fc6603;
  border: 1px solid#fc6603;

}


.otp-input:focus {
  outline: #fc6603;
  border: 1px solid#fc6603;

}
.form-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000;
  padding-bottom: 10px;
}
.googleAuth{
  display: flex;
  cursor: pointer;
  height: 48px;
  background: #ffffff;
  border-radius: 45px;
  border: none;
  margin: 11%;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.googleimg{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8%;
  margin-right: 7%;
}
.googletext{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
